import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Common/Layout";
import SEO from "../components/Common/SEO";
import AccessApplication from "../components/AccessApplication";



const ErrorPage = () => {
  const { t } = useTranslation();
  
  return (
    <Layout>
      <SEO />
      <AccessApplication
        title={t("Info.28")}
        subtitle=""
      />
    </Layout>
  );

}

export default ErrorPage;
